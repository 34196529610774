import { css } from '@emotion/react';
import React from 'react';
import { mediaSp } from '../styles/media-query';

export default function Title({ en, jp }) {
  return (
    <>
      <div css={wrapperStyle} className="titlewrap">
        <p css={englishStyle}>{en}</p>
        <h1 css={headingStyle}>{jp}</h1>
      </div>
    </>
  );
}

const wrapperStyle = css`
  position: relative;
  z-index: 50;
  padding-top: 182px;

  ${mediaSp} {
    padding-top: 92px;
  }
`;

const headingStyle = css`
  position: absolute;
  top: 312px;
  left: 80px;
  color: #6c6f7e;
  font-size: 35px;
  letter-spacing: 0.1em;
  font-family: 'Noto Sans JP';

  ${mediaSp} {
    @media (min-width: 501px) {
      top: 220px;
      left: 40px;
    }

    padding-bottom: 40px;
    font-size: 20px;
    letter-spacing: 0.1em;
    line-height: 1.45;
    position: absolute;
    top: 131px;
    left: 20px;
  }
`;

const englishStyle = css`
  color: #fff;
  font-size: 136px;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin-bottom: 0;
  line-height: 1.4;
  padding: 0;
  font-family: 'Noto Sans JP';
  white-space: nowrap;

  ${mediaSp} {
    font-size: 100px;
    line-height: 1;
    letter-spacing: 0.09em;
    margin-right: 60%;
    white-space: normal;

    @media (max-width: 500px) {
      font-size: 56px;
    }
  }
`;
