import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { mediaSp } from '../styles/media-query';

export default function BackButton({ path }) {
  return (
    <Link
      to={path}
      css={css`
        display: flex;
        width: 285px;
        height: 44px;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 8px #0000001a;
        gap: 10px;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 1.2px;
        font-weight: 900;
        color: #252a40;
        background-color: #fff;
        border-radius: 9999px;

        ${mediaSp} {
          width: 292px;
          gap: 4px;
          padding-left: 10px;
        }

        position: relative;
        overflow: hidden;
        text-decoration: none;
        display: inline-block;
        padding: 12px 30px;
        text-align: center;
        outline: none;
        transition: ease 0.2s;

        :before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background: #252a40;
          width: 100%;
          height: 100%;
          transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
          transform: scale(0, 1);
          transform-origin: right top;
        }

        span {
          position: relative;
          z-index: 3;
          color: #333;

          svg {
            transform: scale(-1, 1);
          }
        }

        &:hover,
        &:active,
        &:focus {
          color: #252a40;
        }

        &:hover {
          span {
            color: #fff;

            svg path {
              stroke: #fff;
            }
          }

          :before {
            transform-origin: left top;
            transform: scale(1, 1);
          }
        }
      `}
    >
      <span
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5.325"
          height="9.649"
          viewBox="0 0 5.325 9.649"
        >
          <path
            id="パス_42287"
            data-name="パス 42287"
            d="M2478.037,6803.851l4.118,4.118-4.118,4.118"
            transform="translate(-2477.33 -6803.144)"
            fill="none"
            stroke="#252A40"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </svg>
        <p
          css={css`
            margin-left: 10px;
          `}
        >
          Back
        </p>
      </span>
    </Link>
  );
}
