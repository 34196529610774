import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/seo';
import SubPageWrapper from '../components/SubPageWrapper';
import Inner from '../components/Inner';
import Title from '../components/Title';
import Box from '../components/Box';
import * as Styles from '../styles/pages/_about.module.scss';
import BackButton from '../components/back-button';

export default function About() {
  const breadcrumb = [{ label: '会社紹介', url: '/about' }];

  return (
    <>
      <Seo
        url="/about"
        title="会社情報｜Uniforce（ユニフォース）株式会社"
        description="会社情報や所属する公認会計士の情報などを掲載しています。"
        image="https://uniforce.co.jp/og-image.jpg"
        breadcrumbs={breadcrumb}
      />
      <Layout breadcrumb={breadcrumb}>
        <SubPageWrapper>
          <div className={Styles.about_page}>
            <Inner>
              <div className={Styles.title__wrapper}>
                <Title en="Our Company" jp="会社概要" />
              </div>
              <div className={Styles.margin} />
              <Box>
                <div className={Styles.about_page__aboutus}>
                  <table>
                    <tr>
                      <th>会社名</th>
                      <td>Uniforce株式会社</td>
                    </tr>
                    <tr>
                      <th>代表</th>
                      <td>代表取締役　砂田 和也（公認会計士/税理士）</td>
                    </tr>
                    <tr>
                      <th>設立日</th>
                      <td>2020年8月13日</td>
                    </tr>
                    <tr>
                      <th>資本金</th>
                      <td>628,248,000円　※資本準備金含む</td>
                    </tr>
                    <tr>
                      <th>所在地</th>
                      <td>
                        〒150-0001 東京都渋谷区神宮前5丁目52-2 青山オーバルビル8階
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td className={Styles.google_map_iframe_wrapper}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.598419824249!2d139.70491283891778!3d35.66226447132616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b5f78a5987f%3A0x4df7ceeee5b2b906!2z6Z2S5bGx44Kq44O844OQ44Or44OT44Or!5e0!3m2!1sja!2sjp!4v1729594221525!5m2!1sja!2sjp"
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </td>
                    </tr>
                    <tr>
                      <th>従業員数</th>
                      <td>45名 ※業務委託含む</td>
                    </tr>
                    <tr>
                      <th>事業内容</th>
                      <td>
                        IPO支援事業、BPOTech事業、IRTech事業、バックオフィス教育事業、HRTech事業
                      </td>
                    </tr>
                    <tr>
                      <th>主要取引銀行</th>
                      <td>みずほ銀行</td>
                    </tr>
                    <tr>
                      <th>主要株主</th>
                      <td>
                        <p>
                          役員陣
                        </p>
                        <p>
                          East Ventures株式会社
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>提携先</th>
                      <td>
                        税理士法人wimg、その他弁護士事務所、司法書士事務所等
                      </td>
                    </tr>
                    <tr>
                      <th>有料職業紹介事業 許可番号</th>
                      <td>
                        13-ユ-314803
                      </td>
                    </tr>
                  </table>
                </div>
              </Box>
              <div className={Styles.margin} />

              <div className={Styles.button__wrapper}>
                <div className={Styles.about_page__btn}>
                  <BackButton path="/" />
                </div>
              </div>
            </Inner>
          </div>
        </SubPageWrapper>
      </Layout>
    </>
  );
}
