// extracted by mini-css-extract-plugin
export var about_member_container = "_about-module--about_member_container--f2070";
export var about_page = "_about-module--about_page--c81ca";
export var about_page__aboutus = "_about-module--about_page__aboutus--74108";
export var about_page__btn = "_about-module--about_page__btn--63315";
export var about_page__ex = "_about-module--about_page__ex--3c396";
export var about_page__message = "_about-module--about_page__message--b018c";
export var about_page__picture = "_about-module--about_page__picture--8d5c9";
export var about_page__subtitle = "_about-module--about_page__subtitle--cbdb2";
export var about_page__text = "_about-module--about_page__text--c9f67";
export var about_page__text__jobtitle = "_about-module--about_page__text__jobtitle--ab73e";
export var about_page__text__name = "_about-module--about_page__text__name--00119";
export var button__wrapper = "_about-module--button__wrapper--ffd51";
export var google_map_iframe_wrapper = "_about-module--google_map_iframe_wrapper--c9cb5";
export var margin = "_about-module--margin--9f582";
export var title__wrapper = "_about-module--title__wrapper--ad05a";